.Carousel__Projects .swiper-container {
  position: relative;
  max-width: 300px;
}

.Carousel__Projects .swiper-container > .swiper-slide__content {
  position: absolute;
  top: 0;
}

.Carousel__Projects .swiper-slide {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(0.5);
  filter: drop-shadow(5px 3px 8px rgba(0, 0, 0, 0.25));
  max-width: 300px;
}

.Carousel__Projects .swiper-slide-active {
  transform: scale(0.7);
  z-index: 1090;
}

.Carousel__Projects .swiper-slide-prev,
.Carousel__Projects .swiper-slide-next {
  transform: scale(0.6);
  z-index: 1080;
}

.Carousel__Projects .swiper-pagination {
  position: relative;
  margin: 1rem 0;
}

.Carousel__Projects .swiper-pagination-bullet {
  background: var(--mainFontColor);
}

.Carousel__Projects .CarouselImg {
  max-width: 350px;
}

@media screen and (max-width: 900px) and (min-height: 698px) {
  .Carousel__Projects .CarouselImg {
    max-width: 500px;
  }
}

/* Small Laptop */
@media screen and (min-width: 900px) and (min-height: 400px) {
  .Carousel__Projects .CarouselImg {
    max-width: 500px;
  }

  .Carousel__Projects .swiper-container {
    max-width: none;
  }
  .Carousel__Projects .swiper-pagination {
    position: relative;
    margin: 0.5rem 0;
  }
  .Carousel__Projects .swiper-slide {
    transform: scale(0.6);
    max-width: none;
  }

  .Carousel__Projects .swiper-slide-active {
    transform: scale(0.8);
  }

  .Carousel__Projects .swiper-slide-prev,
  .Carousel__Projects .swiper-slide-next {
    transform: scale(0.7);
  }
}

/* Desktop */
@media screen and (min-width: 900px) and (min-height: 700px) {
  .Carousel__Projects .CarouselImg {
    max-width: none;
  }
  .Carousel__Projects .swiper-pagination {
    margin: 1.5rem 0;
  }
  .Carousel__Projects .swiper-slide {
    transform: scale(0.7);
  }

  .Carousel__Projects .swiper-slide-active {
    transform: scale(0.9);
  }

  .Carousel__Projects .swiper-slide-prev,
  .Carousel__Projects .swiper-slide-next {
    transform: scale(0.8);
  }
}
