:root {
  --mainBgColor: #ffffff;
  --mainFontColor: #002d3e;
  --secondaryBgColor: #002d3e;
  --secondaryFontColor: #ffffff;
  --thirdBgColor: #e66b00;
  --thirdFontColor: #ffffff;
  --thirdFontColorAlt: #b1b1b1;
  --neutralBgColor: #e6e6e6;
  --neutralFontColor: #424b4d;
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--mainBgColor);
  font-family: "Lato", sans-serif;
  width: 100%;
  overflow-x: hidden;
}

hr {
  width: 60%;
  margin: auto;
}
