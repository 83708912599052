@media screen and (min-width: 900px) and (min-height: 600px) {
  .DownBtn {
    position: relative;
    height: 24px;
    width: 24px;
    margin: auto;
    align-self: center;
    justify-self: center;
  }

  .DownBtn span {
    position: absolute;
    top: -40px;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid var(--mainFontColor);
    border-bottom: 1px solid var(--mainFontColor);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb05 1.5s infinite;
    animation: sdb05 1.5s infinite;
    box-sizing: border-box;
  }

  @-webkit-keyframes sdb05 {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      -webkit-transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }

  @keyframes sdb05 {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
}
