.Home {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Home__Content {
  width: 100%;
  color: var(--mainFontColor);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Home__Content p {
  padding: 5rem 2rem;
  margin: 2vh 0;
  opacity: 0;
  animation: fadeee 1s forwards;
  font-size: calc(0.9rem + 1vh);
  padding: calc(0.6rem + 2.5vh) 2rem;
  font-family: "Karla", sans-serif;
}

.Home__MyPicture {
  width: min-content;
  margin: auto;
  padding: 0;
  display: flex;
  align-self: center;
  justify-self: center;
  overflow: hidden;
}

.Home__MyPicture img {
  width: 100%;
  height: 100%;
}

@keyframes fadeee {
  100% {
    opacity: 1;
  }
}

.Home__Content div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Home__MyPicture {
  width: 100%;
  max-width: 200px;
}

/* Large mobile */
@media screen and (max-width: 900px) and (min-height: 750px) {
  .Home__Content p {
    padding: calc(0.6rem + 2.5vh) 2rem;
    font-size: calc(1.25rem + 1.2vh);
  }

  .Home__MyPicture {
    width: 100%;
    max-width: calc(300px - 10vh);
  }
}

/* Tablet */
@media screen and (min-width: 500px) and (min-height: 600px) {
  .Home__Content p {
    font-size: calc(0.9rem + 2vw + 1vh);
  }
}

/* Sm Laptop */
@media screen and (min-width: 900px) and (min-height: 600px) {
  .Home {
    min-height: calc(100vh);
  }

  .Home__Content {
    padding: 0 1rem;
    justify-content: space-between;
  }

  .Home__Content p {
    font-weight: 400;
    max-width: calc(70vw - 2rem);
  }

  .Home__MyPicture {
    margin: 0;
    width: 30vw;
    max-width: calc(30vw - 2rem);
  }

  .Home__MyPicture img {
    width: 100%;
    height: 100%;
  }
}

/* Md Laptop */
@media screen and (min-width: 1300px) and (min-height: 600px) {
  .Home__Content {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
}

/* Desktop */
@media screen and (min-width: 1600px) and (min-height: 700px) {
  .Home__Content {
    padding: 4rem;
  }

  .Home__Content p {
    max-width: calc(100vw - 4rem - 500px);
  }

  .Home__MyPicture {
    max-width: 400px;
  }
}
