.Projects {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
}

.Projects__Instructions {
  padding: 0 1rem;
  color: var(--mainFontColor);
  font-size: calc(0.9rem + 0.6vh);
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.Projects__AllProjects {
  font-size: 1.3rem;
  padding: 9px 23px;
  align-self: center;
}

.Projects__blur {
  opacity: 0.2;
}

/* Small Laptop */
@media screen and (min-width: 900px) and (min-height: 400px) {
  .Projects {
    margin-bottom: 20px;
    min-height: 90vh;
    scroll-margin-top: 70px;
  }

  .Projects__Instructions {
    font-size: calc(1.5rem + 0.9vh);
    padding: 1.5rem;
  }

  .Projects__DownBtn {
    margin: auto;
    margin-top: 1.5rem;
  }
}

/* Desktop */
@media screen and (min-width: 900px) and (min-height: 700px) {
  .Projects__Instructions {
    font-size: calc(1.7rem + 0.9vh);
    padding: 3rem;
  }
}

/* Lg Desktop */
@media screen and (min-width: 1400px) {
  .Projects {
    scroll-margin-top: 100px;
  }

  .Projects__DownBtn {
    margin-top: 3rem;
  }
}
