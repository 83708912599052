.Button {
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  padding: 9px 23px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #000000;
}

.Button:active {
  position: relative;
  top: 1px;
}

.Button:disabled {
  filter: grayscale(1) opacity(0.4);
  cursor: not-allowed;
}
