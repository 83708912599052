.Navbar__Mobile {
  position: fixed;
  z-index: 1080;
  bottom: 0;
  width: 100%;
  height: 10vh;
  min-height: 80px;
  padding: 0.5rem 0;
  background: var(--neutralBgColor);
  display: flex;
  justify-content: center;
}

.Navbar__Mobile ul {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.6rem;
}

.Navbar__Mobile li a {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: baseline;
  gap: 0.3rem;
  filter: grayscale(0.96) brightness(3.1);
  user-select: none;
  cursor: pointer;
}

.Navbar__Mobile img {
  height: calc(20px + 1vw);
}

.Navbar__Mobile li a:active,
.Navbar__Mobile li a:hover {
  filter: grayscale(0) brightness(1);
}

.Navbar__Mobile span {
  color: var(--mainFontColor);
  font-family: "Inter", sans-serif;
  font-size: calc(0.7rem + 0.5vh);
}

.Navbar__Desktop {
  display: none;
}

/* Small Laptop */
@media screen and (min-width: 900px) and (min-height: 400px) {
  .Navbar__Mobile {
    display: none;
  }

  .Navbar__Desktop {
    position: fixed;
    z-index: 1090;
    top: 0;
    width: 100%;
    padding: 0.5rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--secondaryBgColor);
    font-family: "Lato", sans-serif;
  }

  .Navbar__Desktop__Brand {
    width: 15vw;
    overflow: hidden;
  }

  .Navbar__Desktop__Brand img {
    width: 100%;
  }

  .Navbar__Desktop__List {
    display: flex;
    gap: 1.5rem;
    font-size: calc(0.8rem + 1vw);
    font-weight: 400;
    color: var(--secondaryFontColor);
  }

  .Navbar__Desktop li {
    cursor: pointer;
    position: relative;
  }

  .Navbar__Desktop li::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: var(--secondaryFontColor);
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  .Navbar__Desktop li:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }

  .Navbar__Desktop li a {
    color: var(--secondaryFontColor);
  }

  .Navbar__Desktop img {
    height: calc(1.5rem + 1vw);
    margin-top: calc((1.5rem + 1vw) / 5);
  }

  .Navbar__Desktop__SideContent {
    color: var(--secondaryFontColor);
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .Navbar__Desktop__CV {
    box-shadow: inset 0px 34px 0px -9px #d36600;
    background-color: #eb7100;
    border: 1px solid #4d2400;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    padding: 9px 23px;
    text-decoration: none;
    text-shadow: 0px -1px 0px #002d3e;
    font-size: calc(0.3rem + 1vw);
  }

  .Navbar__Desktop__CV:active {
    position: relative;
    top: 1px;
  }
}

/* Sm Laptop */
@media screen and (min-width: 900px) and (min-height: 700px) {
  .Navbar__Desktop {
    padding: 1rem 1.5rem;
  }

  .Navbar__Desktop__List {
    font-size: calc(1rem + 1vw);
  }

  .Navbar__Desktop img {
    height: 100%;
  }

  .Navbar__Desktop__CV {
    font-size: calc(0.4rem + 1vw);
  }
}

/* Md Laptop */
@media screen and (min-width: 1300px) and (min-height: 600px) {
  .Navbar__Desktop {
    padding: 1rem 4rem;
  }

  .Navbar__Desktop__Brand {
    width: 16vw;
    max-width: 250px;
    overflow: hidden;
  }
}

/* Desktop */
@media screen and (min-width: 1600px) and (min-height: 700px) {
  .Navbar__Desktop {
    padding: 1rem 6rem;
  }

  .Navbar__Desktop__CV {
    box-shadow: inset 0px 34px 0px -5px #d36600;
  }
}
