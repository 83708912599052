.LangSelector {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.LangSelector img {
  width: 40px;
}

.LangSelector ul {
  background: var(--mainFontColor);
  font-size: 1.5rem;
  gap: 0.5rem;
  font-size: calc(0.7rem + 1vw);
  font-weight: 300;
  display: flex;
}

.LangSelector li {
  cursor: pointer;
}

.LangSelector img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
