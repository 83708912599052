.ContactContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  gap: 5rem;
  margin-bottom: 15vh;
}

.ContactContainer__Form {
  max-width: 550px;
  width: 100%;
}

.VerticalSeparator {
  background: var(--mainFontColor);
  height: 60vh;
  width: 1px;
  margin: 1rem 3rem;
  display: none;
}

.ContactContainer__Moreoptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.ContactContainer__Moreoptions__DecoImg {
  margin: 1rem 0;
  max-width: 500px;
}

.ContactContainer__Moreoptions p {
  font-size: 1.4rem;
  color: var(--mainFontColor);
  text-align: center;
}

.ContactContainer__LinkIcos {
  display: flex;
  gap: 0.5rem;
}

.ContactContainer__LinkIcos a:hover {
  filter: brightness(2.5);
}

@media screen and (min-width: 900px) and (min-height: 600px) {
  .ContactContainer {
    scroll-margin-top: 44px;
  }
  .ContactContainer__Moreoptions__DecoImg {
    width: calc(280px + 4vw);
    height: auto;
  }
}

@media screen and (min-width: 1150px) and (min-height: 800px) {
  .ContactContainer__Moreoptions__DecoImg {
    width: calc(350px + 7vw);
    height: auto;
  }

  .ContactContainer__Moreoptions p {
    font-size: 1.4rem;
    color: var(--mainFontColor);
  }
}

@media screen and (min-width: 1360px) {
  .VerticalSeparator {
    display: flex;
    align-self: center;
  }
}
