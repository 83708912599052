.ProjectContainer__background {
  background: rgba(0, 0, 0, 0.838);
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1070;
}

.ProjectContainer {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background: #eee;
  z-index: 1080;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 1500px;
  padding: 4rem 2rem;
  gap: 4rem;
}

.ProjectContainer__ExitBtn {
  position: absolute;
  font-size: 2rem;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.ProjectContainer__ExitBtn:hover,
.ProjectContainer__ExitBtn:active {
  color: rgb(183, 8, 8);
}

.ProjectContainer__Slides {
  width: 300px;
}

.ProjectContainer__Content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2rem;
  margin: auto;
  text-align: left;
}

.ProjectContainer h2 {
  font-size: calc(2rem + 1vw);
}
.ProjectContainer__Techs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-self: center;
  align-items: center;
}
.ProjectContainer h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.ProjectContainer p {
  font-size: 1.55rem;
}

.ProjectContainer__DescriptionList li {
  font-size: 1.55rem;
  list-style: square;
  list-style-position: inside;
}

.ProjectContainer__LinkButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  padding-top: 2rem;
}

.ProjectContainer__LinkButtons button {
  min-width: 175px;
}

.ProjectContainer__PrevSlide,
.ProjectContainer__NextSlide {
  position: absolute;
  font-size: 2rem;
  cursor: pointer;
}
.ProjectContainer__PrevSlide {
  left: 40px;
  bottom: 15px;
}
.ProjectContainer__NextSlide {
  right: 40px;
  bottom: 15px;
}

.ProjectContainer__PrevSlide:hover,
.ProjectContainer__NextSlide:hover {
  color: var(--mainFontColor);
}

.ProjectContainer__selected {
  display: flex;
}
.ProjectContainer__hidden {
  display: none;
}

/* Tablet */
@media screen and (min-width: 500px) and (min-height: 500px) {
  .ProjectContainer {
    top: 67%;
  }
  .ProjectContainer__Content {
    text-align: justify;
    hyphens: auto;
  }

  .ProjectContainer__LinkButtons {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .ProjectContainer__PrevSlide {
    left: 40px;
    bottom: 60px;
  }
  .ProjectContainer__NextSlide {
    right: 40px;
    bottom: 60px;
  }
}

/* Small Laptop */
@media screen and (min-width: 900px) and (min-height: 400px) {
  .ProjectContainer {
    flex-direction: row-reverse;
    padding: 2rem;
    top: 50%;
  }

  .ProjectContainer__ExitBtn {
    transform: scale(1.2);
    right: 30px;
    top: 20px;
  }

  .ProjectContainer__Slides {
    width: 400px;
  }

  .ProjectContainer h2 {
    text-align: justify;
  }

  .ProjectContainer__Techs {
    align-self: flex-start;
    align-items: flex-start;
  }

  .ProjectContainer__PrevSlide {
    right: 0;
    bottom: 45%;
    font-size: 3rem;
  }

  .ProjectContainer__NextSlide {
    right: 30px;
    bottom: 45%;
    font-size: 3rem;
  }
}
/* Desktop */
@media screen and (min-width: 900px) and (min-height: 700px) {
  .ProjectContainer {
    flex-direction: row-reverse;
    padding: 4rem;
  }
}
/* Large Desktop */
@media screen and (min-width: 1200px) and (min-height: 400px) {
  .ProjectContainer__Slides {
    width: 600px;
  }
}
